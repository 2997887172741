export const fetchItineraryData = async (
  originDestinationInfo,
  passengerTypes
) => {
  // console.log(originDestinationInfo, passengerTypes, "From fetch itenary data");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Conversation-ID": "2021.01.DevStudio",
      Authorization:
        "Bearer T1RLAQLqWYwBZEnFhVtui6V0onf5v/KpPDzNaEL2T1VJVjG/KBBQdutogYu52q4TKooaTrbWAADQuGuIr5WYCDLTj56bI/3/YtMgYQ/OkDVEWojGG7Rhf2YLNhWfIfkOvmsX1E7lysqkEP6Iu8GjXkGlXOFO9Y0TioJrpWvRy++nXPFhRB8hRQ7Bz9WxEUyCsDXknNkjdM1X+pjbOsegprqkkOlPJh9e+hLbjNpQjmtL24/EqQtpB2JBx21CaCCzj9xmNPWhhO6lEAcjksJgWCevMgrbiv7GBBpbhPsx+/X19GjOTWhmZxVqMWI4mo8X75XiFFiPjZkV/usdFde+AK8M5FgsdaKkzQ**",
    },
    body: JSON.stringify({
      OTA_AirLowFareSearchRQ: {
        Version: "5",
        POS: {
          Source: [
            {
              PseudoCityCode: "7C18",
              RequestorID: {
                Type: "1",
                ID: "1",
                CompanyName: { Code: "TN" },
              },
            },
          ],
        },
        OriginDestinationInformation: originDestinationInfo,
        TravelPreferences: {
          TPA_Extensions: {
            DataSources: {
              NDC: "Disable",
              ATPCO: "Enable",
              LCC: "Disable",
            },
            PreferNDCSourceOnTie: { Value: false },
          },
        },
        TravelerInfoSummary: {
          AirTravelerAvail: [{ PassengerTypeQuantity: passengerTypes }],
        },
        TPA_Extensions: {
          IntelliSellTransaction: { RequestType: { Name: "200ITINS" } },
        },
      },
    }),
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://api.platform.sabre.com/v5/offers/shop", // https://api.cert.platform.sabre.com/v5/offers/shop
      requestOptions
    );
    if (!response.ok) {
      throw new Error("Failed to fetch itinerary data");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching itinerary data:", error);
    throw error;
  }
};

export const fetchAirlinesData = async () => {
  try {
    const response = await fetch("https://api.hamsfly.com/api/carrierLogo");
    if (!response.ok) {
      throw new Error("Failed to fetch airline data");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching airlines data:", error);
    throw error;
  }
};
